<template>
  <div class="mt-3">
    <h1 class="mb-3">
      {{ $t('tosc.t') }}
    </h1>
    <b-row>
      <b-col>
        <h5>{{ $t('tosc.hov') }}</h5>
        <p class="text-justify">
          <i18n path="tosc.ovwtxt">
            <template #linkimpr><b-link to="imprint">{{ $t('tosc.ovwtxtl') }}</b-link></template>
            <template #linktos><b-link to="tos">{{ $t('tosc.ovwtxtt') }}</b-link></template>
          </i18n>
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>{{ $t('tosc.heg') }}</h5>
        <b-list-group class="pb-3">
          <b-list-group-item v-for="(it, idx) in entries" :key="`l.`+idx" :id="it.h">
            <h6 class="font-weight-bold">{{ $t('tosc.h' + idx, [idx+1]) }}</h6>
            <template v-for="(sit, sidx) in it.s">
              <p class="text-justify" :key="`p.`+idx+`.`+sidx" v-if="sit.l===false">
                {{ $t('tosc.c' + idx) }}
              </p>
              <template v-else>
                <ol v-for="(i,) in sit.e" :key="`ol.`+idx+`.`+sidx+`.`+i" class="text-justify">
                  <li :key="`li.`+idx+`.`+i" :s="idx+1" :x="i">
                    {{ $t(['tosc.c',idx,i].join('')) }}
                    <ul v-if="Array.isArray(sit.s) && sit.s[i-1]!=0" class="pl-0">
                      <li v-for="(ii, ) in sit.s[i-1]" :key="`ul.`+idx+`.`+sidx+`.`+i+`.`+ii">{{ $t(['tosc.c',idx,i,ii].join('')) }}</li>
                    </ul>
                  </li>
                </ol>
              </template>
              <template v-if="sit.link!=null">
                <p :key="`p.`+idx+`.`+sidx+`lnk`"><b-link :href="sit.link" target="_blank">{{ $t('tosc.c'+(idx)+'l') }}</b-link></p>
              </template>
            </template>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>{{ $t('tosc.hvs') }}</h5>
        <p class="text-justify">
          {{ $t('tosc.lv', ['1']) }}
          <i18n path="tosc.lvt">
            <template #date>{{ $d(new Date(2021,4,1), 'ds') }}</template>
          </i18n>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      entries: [
        {h: 'applicability', s: [{l: true, e: 4}]},
        {h: 'terms', s: [{l: true, e: 7}]},
        {h: 'responsibilities', s: [{l: true, e: 9}]},
        {h: 'payments', s: [{l: true, e: 9, s: [0,0,5], link: `${process.env.VUE_APP_PRICELIST}`}]},
        {h: 'termination', s: [{l: true, e: 3}]},
        {h: 'final', s: [{l: true, e: 2}]},
      ],
    };
  },
}
</script>

<style scoped>
ol {
  list-style: none;
}
ol>li {
  position: relative;
}
ol>li:before {
  content: attr(s) "." attr(x) ". ";
  position: absolute;
  left: -2em;
}
</style>